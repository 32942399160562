<template>
    <a-descriptions :column="3">
        <a-descriptions-item label="SN码">
            {{ data?.barcode }}
        </a-descriptions-item>
        <a-descriptions-item label="制品状态">
            <span :style="{ color: getStatusColor(data?.status) }">
                {{ data?.statusDesc }}
            </span>
        </a-descriptions-item>
        <a-descriptions-item label="产品名称">
            {{ data?.productName }}
        </a-descriptions-item>
        <a-descriptions-item label="产品编码">
            {{ data?.productCode }}
        </a-descriptions-item>
        <a-descriptions-item label="规格型号">
            {{ data?.spec }}
        </a-descriptions-item>
        <a-descriptions-item label="工单号">
            {{ data?.cjWorkOrderCode }}
        </a-descriptions-item>
        <a-descriptions-item label="生产订单号">
            {{ data?.orderCode }}
        </a-descriptions-item>
        <a-descriptions-item label="生产批次号">
            {{ data?.productionBatchNo }}
        </a-descriptions-item>
        <a-descriptions-item label="完成时间">
            {{ data?.completeTime }}
        </a-descriptions-item>
    </a-descriptions>
</template>

<script>
import { defineComponent } from "vue";
import { getStatusColor } from "@/utils/common";

export default defineComponent({
    props: {
        data: {
            default: null,
        },
    },
    setup() {
        return { getStatusColor };
    },
});
</script>
